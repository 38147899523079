.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-legend {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(72, 75, 76, 0.8);
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

a {
  color: lightskyblue;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: black;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: black;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: black;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: black;
}

.mapboxgl-popup-close-button {
  color: white;
  font-size: 16pt;
  z-index: 999;
}

/* styles.css */

.legend-container {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 998;
}

.routes-container {
  position: absolute;
  z-index: 997;
}

.legend-toggle-btn {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #171717;
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.legend-toggle-btn:hover {
  background-color: #2a2a2a;
}

.route-toggle-btn {
  position: fixed;
  top: 220px;
  right: 10px;
  background-color: rgb(111, 8, 8);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
  -webkit-font-smoothing: antialiased;
  z-index: 998;
}

.route-toggle-btn:hover {
  background-color: rgb(71, 4, 4);
}

.map-legend {
  background-color: #2a2a2a;
  border-radius: 0px;
  padding: 20px;
  width: 260px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  font-size: 11pt;
  margin-top: 50px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%); /* Initially off-screen */
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  overflow: auto;
}

.legend-visible {
  transform: translateX(0);
}

/* Overlay background layer */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.map-routes {
  background-color: #2a2a2a;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  font-size: 11pt;
  z-index: 999;
  display: none; /* Initially hidden */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80%;
  overflow: auto;
}

/* Visible state for overlay and menu */
.route-menu-visible .map-routes {
  display: block;
}

.route-menu-visible .overlay {
  display: flex;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.legend-icon {
  margin-right: 20px;
  font-weight: bold;
}

.legend-description {
  flex: 1;
  font-size: 10pt;
  color: #c3c3c3;
}

.legend-icon-airspace {
  width: 14px;
  height: 14px;
  margin-right: 20px;
}

.map-legend h4 {
  font-size: 11pt;
  margin-bottom: 10px;
  margin-top: 16px;
}

.legend-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16pt;
  cursor: pointer;
  color: white;
  transition: color 0.3s ease;
}

.legend-close-btn:hover {
  color: #ff0000;
}

/* Statistics Row */
.traffic-stats-row {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #171717;
  color: white;
  padding: 10px 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 997;
  font-size: 10pt;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.traffic-toggle {
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  transition: color 0.3s ease; 
  user-select: none;
}

.traffic-toggle img {
  margin-right: 4px;
  display: inline-block; 
  vertical-align: middle; 
  user-select: none;
}

.traffic-toggle.disabled {
  color: gray;
}

/* Popup styling */
.mapboxgl-popup-content {
  font-family: Arial, sans-serif;
  font-size: 12px;
  background-color: black;
  color: white;
  border-radius: 0px;
  padding: 4px 26px 4px 10px;
  max-height: 200px;
  overflow: auto;
}

.mapboxgl-popup-content a {
  color: #007bff;
}

.mapboxgl-popup-content a:hover {
  color: #0056b3;
}

.hide-label {
  position: fixed;
  left: 10px;
  top: 40px;
  text-shadow: 
    1px 1px 2px rgba(0, 0, 0, 1),
    0 0 5px rgba(0, 0, 0, 1); 
  user-select: none;
}

.traffic-count {
  font-weight: bold;
  font-size: 12pt;
}

/* Toggle styling */

.toggle-container {
  position: absolute;
  top: 60px;
  right: 0px;
  z-index: 1000;
}

.cogwheel-button {
  position: fixed;
  top: 0px;
  right: 50px;
  background-color: #171717;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.cogwheel-button:hover {
  background-color: #2a2a2a;
}

.cogwheel-button img {
  width: 24px;
  height: auto;
}

.toggle-panel {
  background-color: #000b18;
  border-radius: 0px;
  padding: 20px;
  width: 260px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  font-size: 11pt;
  margin-right: 10px;
  max-height: 560px;
  overflow-y: auto;
  user-select: none;
}

.toggle-panel ul {
  list-style: none;
  padding: 0;
}

.toggle-panel li {
  margin-bottom: 8px;
}

.toggle-panel input {
  margin-right: 10px;
}

.fss-title {
  font-size: 18pt;
  font-family: "landa", sans-serif;
  font-weight: 500;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  color: #dfebeb;
  cursor: pointer;
  user-select: none;
}

.fss-title img {
  max-width: 22px;
  margin-left: 8px;
}

@media (max-width: 970px) {
  .fss-title {
    left: 370px;
    transform: none;
  }
}

@media (max-width: 660px) {
  .fss-title {
    display: none;
  }
}

.mapbox-improve-map {
  display: none;
}

.logo {
  z-index: 900;
  pointer-events: auto;
  cursor: pointer;
  width: 100px;
  position: absolute;
  bottom: -6px;
  margin-left: 4px;
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.7));
}

.mapboxgl-ctrl-logo {
  margin-left: 100px !important;
}

.mapboxgl-ctrl-top-right {
  top: 80px !important;
}

.mapboxgl-ctrl-group button {
  background-color: #171717 !important;
  border: none !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 18px !important;
  border-radius: 8px !important;
  border-radius: 0 !important;
}

.mapboxgl-ctrl-group button:hover {
  background-color: #2a2a2a !important;
}

.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dfebeb' viewBox='0 0 24 24'%3E%3Cpath d='M12 4c.6 0 1 .4 1 1v6h6c.6 0 1 .4 1 1s-.4 1-1 1h-6v6c0 .6-.4 1-1 1s-1-.4-1-1v-6H5c-.6 0-1-.4-1-1s.4-1 1-1h6V5c0-.6.4-1 1-1z'/%3E%3C/svg%3E") !important;
  background-size: 50% !important;
}

.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dfebeb' viewBox='0 0 24 24'%3E%3Cpath d='M19 11H5c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1z'/%3E%3C/svg%3E") !important;
  background-size: 50% !important;
}

.route-list {
  background-color: rgb(210, 210, 210);
  color: black;
  padding: 4px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 10pt;
}

.fmp-popup-content {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 0px;
  background-color: black;
}

.flow-measure {
  margin-bottom: 15px;
}

.field {
  margin-bottom: 10px;
  text-align: center;
}

.field strong {
  font-weight: bold;
}

.value {
  text-align: left;
  background-color: white;
  margin: 5px 0px;
  padding: 0px 4px;
  color: #333;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9pt;
  line-height: 13pt;
}

.value-callsign {
  display: block;
  text-align: left;
  margin: 5px 0px;
  color: white;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 10pt;
  line-height: 14pt;
}

.value-verify-route {
  display: block;
  text-align: center;
  background-color: darkred;
  margin: 5px 0px;
  color: white;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 8pt;
  line-height: 14pt;
}

.fmp-title {
  display: inline-block;
  font-size: 12pt;
}

.fmp-disclamer {
  text-align: left;
  background-color: darkred;
  margin: 5px 0px;
  padding: 0px 4px;
  color: white;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 8pt;
}

.value-measure-type {
  background-color: yellow;
  color: black;
  font-weight: bold;
}

.fmp-count {
  padding-left: 6px;
}

.fmp-title-container {
  text-align: center;
}

.measure-active {
  font-weight: bold;
  text-align: left;
  background-color: green;
  margin: 5px 0px;
  padding: 0px 4px;
  color: white;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9pt;
}

.measure-planned {
  font-weight: bold;
  text-align: left;
  background-color: orange;
  margin: 5px 0px;
  padding: 0px 4px;
  color: black;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9pt;
}

.measure-ended {
  font-weight: bold;
  text-align: left;
  background-color: darkred;
  margin: 5px 0px;
  padding: 0px 4px;
  color: white;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9pt;
}

.tactical-measure-text {
font-size: 12pt;
}

html, body, * {
  scrollbar-width: thin !important; /* For Firefox */
  scrollbar-color: #555 #1a1a1a !important; /* Thumb and track colors */
}

*::-webkit-scrollbar {
  width: 8px !important;
}

*::-webkit-scrollbar-track {
  background: #1a1a1a !important;
  border-radius: 4px !important;
}

*::-webkit-scrollbar-thumb {
  background: #2d1b1b !important;
  border-radius: 4px !important;
  transition: background-color 0.3s ease !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #888 !important;
}

.dep-des-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.dep-des-value {
  flex: 1; 
  text-align: center;
  background-color: white;
  margin: 0 0px;
  padding: 0 4px;
  color: #333;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 8pt;
  line-height: 13pt;
}

.dash {
  flex: 0;
  text-align: center;
  padding: 0 4px;
}